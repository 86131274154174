import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

const changeCssClasses = (getter, setter, classListAdd, classListRemove) => {
    let newValue = getter;
    classListRemove.forEach((v) => {
      newValue = newValue.replace(v, "");
    });

    classListAdd.forEach((v) => {
      if (!newValue.includes(v)) {
        newValue = `${newValue} ${v}`;
      }
    });
    setter(newValue);
  }
  
const changeInlineCss = (getter, setter, formatsToAdd, formatsToRemove) => {

  // console.log(`in changeInlineCss; formatsToAdd and formatsToRemove and mergedStylesnext`);
  // console.log(formatsToAdd);
  // console.log(formatsToRemove);
  // Get all current formats, provided they are not included in the formatsToRemove array
  const currentFormats = Object.fromEntries(Object.entries(getter).filter(([k,_]) => !formatsToRemove.includes(k)));
  // Get all new formats, provided they are not included in the formatsToRemove array
  const newFormats = Object.fromEntries(Object.entries(formatsToAdd).filter(([k,_]) => !formatsToRemove.includes(k)));
  // Inline styles are always ADDED to previous list. It is not a replacement. 
  // So we need to unset properties we want to remove
  const removedFormats = Object.fromEntries(formatsToRemove.map((f) => [f, "unset"]));
  // Merge them both into a new dictionary
  const mergedStyles = Object.fromEntries(Object.entries(currentFormats).concat(Object.entries(newFormats).concat(Object.entries(removedFormats))));
  // Store
  // console.log(mergedStyles);
  setter(mergedStyles);
}

const triggerScrollTriggerRefresh = (scrollTriggerIds) => {
  scrollTriggerIds.forEach((trigger) => {
    const scroller = ScrollTrigger.getById(trigger);
    if (scroller!=undefined && scroller!=null)  {
      scroller.refresh();
    }
  });
}

const isMobile = () => {
  try {
    const body = document.getElementsByTagName("body")[0];
    const bodyWidth = body.offsetWidth;
    return (bodyWidth<768 );
  } catch (e) {
    return false;
  }
  
}

const isTablet = () => {
  try {
    const body = document.getElementsByTagName("body")[0];
    const bodyWidth = body.offsetWidth;
    return (bodyWidth>=768 && bodyWidth<1080);
  } catch (e) {
    return false;
  }
}

const isDesktop = () => {
  try {
    const body = document.getElementsByTagName("body")[0];
    const bodyWidth = body.offsetWidth;
    return (bodyWidth>=1080);
  } catch(e) {
    return false;
  }

}

const deviceType = () => {
  if (isMobile()) {
    return "mobile"
  } else if (isTablet()) {
    return "tablet"
  } else return "desktop"
}

const shouldAnimate = (naturalWidth, naturalHeight, adjustmentFactor) => {
  const body = document.getElementsByTagName("body")[0];
  const bodyWidth = body.offsetWidth;
  const realWidth = bodyWidth;
  const tranformRatio = realWidth/(adjustmentFactor*naturalWidth);
  const realHeight = tranformRatio*naturalHeight;
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;
  const isLandscape = viewportWidth > viewportHeight
  const shouldAnimateResult = bodyWidth>=768 && isLandscape && realHeight > viewportHeight;
  // console.log(`>>> shouldAnimate: ${shouldAnimateResult} because naturalWidth=${naturalWidth}; realWidth=${realWidth}; naturalHeight=${naturalHeight}; realHeight=${realHeight}; tranformRatio=${tranformRatio}; shouldAnimateResult=${shouldAnimateResult}`);

  return shouldAnimateResult;

}


// const getTextHeight = (text, maxWidth, fontSize, lineHeight, additionalMargin) {
//   const words = text.split(" ").map(w => w.trim());

//   try {
//     words.slice(lineWordPositionStart).forEach((w, i) => {
      
//       stringLine += (stringLine.length == 0) ? `${w}` : ` ${w}` ;
//       previousWidthObserved = lineWidth;
//       textMetrics =ctx.measureText(stringLine);
//       lineWidth = 1.0*textMetrics.width;
//       // console.log(`textMetrics=${lineWidth} processing stringLine=${stringLine}====== @i[${i}] @ slice[${lineWordPositionStart}]; ` );

//       measuredLineHeight = (textMetrics.fontBoundingBoxAscent!=undefined) 
//                                   ? textMetrics.fontBoundingBoxAscent + textMetrics.fontBoundingBoxDescent 
//                                   : textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent;
      
//       // console.log(`textMetrics.fontBoundingBoxAscent=${textMetrics.fontBoundingBoxAscent}; textMetrics.fontBoundingBoxDescent=${textMetrics.fontBoundingBoxDescent}; measuredLineHeight=${measuredLineHeight}]; Full text metics next; stringLine=${stringLine}` );
//       // console.log(textMetrics);

//       if (lineWidth > maxWidth) {
//         // This is a single string that does not fit the width.
//         // We need to start from sratch with a smaller font
//         // console.log(`|||>>> lineWidth=${lineWidth} exceeds maxWidth=${maxWidth}. do some magic now`);
//         if (stringLine.split(" ").length == 1) {
//           const textReductionFactor =  lineWidth/maxWidth ;
//           currentFontSize = Math.floor(currentFontSize/textReductionFactor);
//           currentLineHeight = Math.floor(currentLineHeight/textReductionFactor);
//           lineCounter = 0 ;
//           lineWordPositionStart = 0;
//           maxWidthObserved = 0;
//           // console.log(`********* single word too big: ${stringLine} measures ${lineWidth} > ${maxWidth}. Start from scratch!!!!`);
//           // console.log(`textReductionFactor=${textReductionFactor}; try with currentFontSize=${currentFontSize} and currentLineHeight=${currentLineHeight}`);
//           throw BreakError;
//         } else {
//           // Here we find the the new word has made width spill.
//           // So we need to create a new line and carry on trying
//           lineCounter++;
//           lineWordPositionStart += i; 
//           maxWidthObserved = (previousWidthObserved > maxWidthObserved) ? previousWidthObserved : maxWidthObserved;
//           // console.log(`line too big: ${stringLine} measures ${lineWidth} > ${maxWidth}. lineWordPositionStart=${lineWordPositionStart}; maxWidthObserved=${maxWidthObserved}`);
//           // console.log(`|||>>> line too big. i=${i}; new lineWordPositionStart=${lineWordPositionStart}; word=${w}`);
//           throw BreakError;
//         }
//       }
//     });

//     // If we get to this stage, all words fit. 
//     // The question is, have we made it too small?
//     // That depends on the heigh and max width observed
//     containerHeight = measuredLineHeight * lineCounter;

//     // console.log(`>>>> textContainerReduction. measuredLineHeight=${measuredLineHeight}; lineCounter${lineCounter}; containerHeight=${containerHeight}`);

//     if (containerHeight <= targetHeight) {
//       // console.log(`found!!! maxWidthObserved=${maxWidthObserved}; width=${maxWidth}; containerHeight=${containerHeight}; targetHeight=${targetHeight}; font=${currentFontSize}px/${currentLineHeight}px  ${fontFamily}; top: ${(targetHeight-containerHeight)/2}px`);
//       sizeFound = true;
//     } else {
//       // console.log(`NOT found!!! containerHeight=${containerHeight}; targetHeight=${targetHeight}; font=${currentFontSize}px/${currentLineHeight}px  ${fontFamily}; maxWidthObserved=${maxWidthObserved}; width=${maxWidth}; `);
//       lineCounter = 0 ;
//       lineWordPositionStart = 0;
//       maxWidthObserved = 0;
//       const reduce = 0.95; //(lastAdjustment==0) ? 0.9 : 0.5*lastAdjustment;
//       const augment = 1.05; // (lastAdjustment==0) ? 1.1 : 0.5*lastAdjustment;
//       const textReductionFactor =  reduce; //(containerHeight/targetHeight>1) ? reduce : augment ;
//       lastAdjustment = textReductionFactor;
//       currentFontSize = Math.max(1, Math.round(currentFontSize*textReductionFactor));
//       currentLineHeight =Math.max(1, Math.round(currentLineHeight*textReductionFactor));
//       lineCounter = 0 ;
      
//       throw BreakError;
//     }
//   } catch (err) {
//     if (err !== BreakError) throw err;
//   }

// }

const textContainerReduction = (containerId, text, maxWidth, targetHeight, fontFamily, fontSize, lineHeight, additionalMargin) => {


  // Don't do if isMobile
  if (isMobile()) {
    return {
      fontSize: `${fontSize/2}px`,
      lineHeight: `${3+lineHeight/2}px`,
      top: `${additionalMargin}px`,
      width: "100%"
    }
  }

  const BreakError = {};
  let sizeFound = false;
  const words = text.split(" ").map(w => w.trim());
  let [iterCount, MAX_ITERS] = [0, 150];
  const safeContainerId = `tcr${containerId}`;
  const canvas = document.createElement('canvas');
        canvas.id = safeContainerId;
        canvas.width = maxWidth;
        canvas.maxWidth = maxWidth;
        canvas.height = targetHeight;
        canvas.style.zIndex = 0;
        canvas.style.position = "absolute";
        canvas.style.top = 0;
        canvas.style.left = 0;
        canvas.style.background = "#fff";
        canvas.style.color = "#fff";
        canvas.style.font = `${fontSize}px/${lineHeight}px  ${fontFamily}`;
        // canvas.style.fontWeight = fontWeight;
        // canvas.style.fontSize = fontSize;
        canvas.style.lineHeight=lineHeight;

  // const body = document.getElementsByTagName("body")[0];
  // body.appendChild(canvas);

  const ctx = canvas.getContext("2d");
  
  // console.log(`>>>>>>>>>>>>>> textContainerReduction > targetHeight=${targetHeight}; maxWidth=${maxWidth} safeContainerId=${safeContainerId}; ctx next:`);
  // console.log(ctx)

  let lineCounter = 0;
  let lineWordPositionStart = 0;
  let maxWidthObserved = 0;
  let previousWidthObserved = 0;
  let currentFontSize = fontSize;
  let currentLineHeight = lineHeight;
  let measuredLineHeight = lineHeight;
  let textMetrics = null;
  let containerHeight = 0;
  let lastAdjustment = 0;

  while(!sizeFound && ++iterCount<=MAX_ITERS) {
    let stringLine = "";
    let lineWidth = 0;
    
    canvas.style.font = `${currentFontSize}px/${currentLineHeight}px  ${fontFamily}`;
    ctx.font = `${currentFontSize}px/${currentLineHeight}px ${fontFamily}`;

    // console.log(`attempt ${iterCount} starting in word@${lineWordPositionStart} (${words[lineWordPositionStart]}) with currentFont = ${currentFontSize}/${currentLineHeight}; maxWidth=${maxWidth}; maxWidthObserved=${maxWidthObserved}`)
    // console.log(ctx.font);
    try {
      words.slice(lineWordPositionStart).forEach((w, i) => {
        
        stringLine += (stringLine.length == 0) ? `${w}` : ` ${w}` ;
        previousWidthObserved = lineWidth;
        textMetrics =ctx.measureText(stringLine);
        lineWidth = 1.0*textMetrics.width;
        // console.log(`textMetrics=${lineWidth} processing stringLine=${stringLine}====== @i[${i}] @ slice[${lineWordPositionStart}]; ` );

        measuredLineHeight = (textMetrics.fontBoundingBoxAscent!=undefined) 
                                    ? textMetrics.fontBoundingBoxAscent + textMetrics.fontBoundingBoxDescent 
                                    : textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent;
        
        // console.log(`textMetrics.fontBoundingBoxAscent=${textMetrics.fontBoundingBoxAscent}; textMetrics.fontBoundingBoxDescent=${textMetrics.fontBoundingBoxDescent}; measuredLineHeight=${measuredLineHeight}]; Full text metics next; stringLine=${stringLine}` );
        // console.log(textMetrics);

        if (lineWidth > maxWidth) {
          // This is a single string that does not fit the width.
          // We need to start from sratch with a smaller font
          // console.log(`|||>>> lineWidth=${lineWidth} exceeds maxWidth=${maxWidth}. do some magic now`);
          if (stringLine.split(" ").length == 1) {
            const textReductionFactor =  lineWidth/maxWidth ;
            currentFontSize = Math.floor(currentFontSize/textReductionFactor);
            currentLineHeight = Math.floor(currentLineHeight/textReductionFactor);
            lineCounter = 0 ;
            lineWordPositionStart = 0;
            maxWidthObserved = 0;
            // console.log(`********* single word too big: ${stringLine} measures ${lineWidth} > ${maxWidth}. Start from scratch!!!!`);
            // console.log(`textReductionFactor=${textReductionFactor}; try with currentFontSize=${currentFontSize} and currentLineHeight=${currentLineHeight}`);
            throw BreakError;
          } else {
            // Here we find the the new word has made width spill.
            // So we need to create a new line and carry on trying
            lineCounter++;
            lineWordPositionStart += i; 
            maxWidthObserved = (previousWidthObserved > maxWidthObserved) ? previousWidthObserved : maxWidthObserved;
            // console.log(`line too big: ${stringLine} measures ${lineWidth} > ${maxWidth}. lineWordPositionStart=${lineWordPositionStart}; maxWidthObserved=${maxWidthObserved}`);
            // console.log(`|||>>> line too big. i=${i}; new lineWordPositionStart=${lineWordPositionStart}; word=${w}`);
            throw BreakError;
          }
        }
      });

      // If we get to this stage, all words fit. 
      // The question is, have we made it too small?
      // That depends on the heigh and max width observed
      containerHeight = measuredLineHeight * lineCounter;

      // console.log(`>>>> textContainerReduction. measuredLineHeight=${measuredLineHeight}; lineCounter${lineCounter}; containerHeight=${containerHeight}`);

      if (containerHeight <= targetHeight) {
        // console.log(`found!!! maxWidthObserved=${maxWidthObserved}; width=${maxWidth}; containerHeight=${containerHeight}; targetHeight=${targetHeight}; font=${currentFontSize}px/${currentLineHeight}px  ${fontFamily}; top: ${(targetHeight-containerHeight)/2}px`);
        sizeFound = true;
      } else {
        // console.log(`NOT found!!! containerHeight=${containerHeight}; targetHeight=${targetHeight}; font=${currentFontSize}px/${currentLineHeight}px  ${fontFamily}; maxWidthObserved=${maxWidthObserved}; width=${maxWidth}; `);
        lineCounter = 0 ;
        lineWordPositionStart = 0;
        maxWidthObserved = 0;
        const reduce = 0.95; //(lastAdjustment==0) ? 0.9 : 0.5*lastAdjustment;
        const augment = 1.05; // (lastAdjustment==0) ? 1.1 : 0.5*lastAdjustment;
        const textReductionFactor =  reduce; //(containerHeight/targetHeight>1) ? reduce : augment ;
        lastAdjustment = textReductionFactor;
        currentFontSize = Math.max(1, Math.round(currentFontSize*textReductionFactor));
        currentLineHeight =Math.max(1, Math.round(currentLineHeight*textReductionFactor));
        lineCounter = 0 ;
        
        throw BreakError;
      }
    } catch (err) {
      if (err !== BreakError) throw err;
    }
    

    // currentHeight = 1.0*ctx.measureText(text).height;
    // if (currentHeight >= 0.9*targetHeight && currentHeight <= targetHeight) {
    //   console.log(`textContainerReduction > FOUND!!! height=${currentHeight}; fontSize=${canvas.style.fontSize}; lineHeight=${canvas.style.lineHeight}`);
    //   sizeFound = true;
    // } else {
    //   const textReductionFactor =  currentHeight/targetHeight ;
    //   canvas.style.fontSize *= textReductionFactor;
    //   canvas.style.lineHeight *= textReductionFactor;
    //   console.log(`textContainerReduction > targetHeight=${targetHeight}; currentHeight=${currentHeight}; textReductionFactor=${textReductionFactor}; fontSize=${canvas.style.fontSize}; lineHeight=${canvas.style.lineHeight}`);
    // }
  }

  const element = document.getElementById(safeContainerId);
  // element.remove(); 
  // console.log(`NOTE that element findByID is `);
  // console.log(element);

  
  return {
    fontSize: `${currentFontSize}px`,
    lineHeight: `${currentLineHeight}px`,
    top: `${additionalMargin + (targetHeight-containerHeight)/2}px`
  }



}

  const toStandardPage = (page) => {
    var imgLink = page.preview_image == null ? "" : page.preview_image.scales.preview.download;
    try {
        return {
            "@id": page["@id"],
            uid: page.UID,
            title: page.title,
            description: page.description,
            language: (page.language != undefined) ? page.language.token : "",
            image: imgLink.replace(process.env.RAZZLE_API_PATH, ""),
            link: page["@id"].replace(process.env.RAZZLE_API_PATH, ""), 
            fsegments: (page.fsegments!=null && page.fsegments!=undefined) ? page.fsegments : [],
            fgenres: (page.fgenres!=null && page.fgenres!=undefined) ? page.fgenres : [],
            fstructures: (page.fstructures!=null && page.fstructures!=undefined) ? page.fstructures : [],
            fcompositions: (page.fcompositions!=null && page.fcompositions!=undefined) ? page.fcompositions : [],
            faddons: (page.faddons!=null && page.faddons!=undefined) ? page.faddons : [],

        }   
    } catch  (e) {
        console.log(e);
        return null;
    } 
}
  const toStandardPages = (pages) => {
    return pages.map( (page) => toStandardPage(page));
  }

  function shuffle (t)
{ let last = t.length
  let n
  while (last > 0)
  { n = rand(last)
    swap(t, n, --last)
  }

  return t;
}

const rand = n =>
  Math.floor(Math.random() * n)

function swap (t, i, j) { let q = t[i]
  t[i] = t[j]
  t[j] = q
  return t
}

const isBrowser = () => {
  try {
    return window!==undefined ;
  } catch (e) {
    return false
  }
}

const bodyPercentToAbsolutePositioning = (percent) => {
  const adjustedPercent = (percent<1) ? percent : percent/100;
  if (isBrowser()) {
    const body = document.getElementsByTagName("body")[0];
    const bodyWidth = body.offsetWidth;
    // const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;
    const sideMargin = (viewportWidth-bodyWidth)/2.0;

    return sideMargin + (bodyWidth*adjustedPercent)

  } else {
    return 0;
  }
  
}

const isImageSpotVisible = (containerWidth, containerHeight, imageWidth, imageHeight, scaleFactor, displaceX, displaceY, imageSpotX, imageSpotY) => {
  const autoScaleFactor = isMobile() ? containerHeight/(imageHeight*scaleFactor) : containerWidth/(imageWidth*scaleFactor);
  const [spotPositionX, spotPositionY] = [autoScaleFactor*imageSpotX+displaceX, autoScaleFactor*imageSpotY+displaceY]
  return spotPositionX<containerWidth && spotPositionY<containerHeight;
}

const isImageGreedy = (containerWidth, containerHeight, imageWidth, imageHeight, scaleFactor, displaceX, displaceY) => {
  let errorMessage = "OK";
  // const autoScaleFactor = containerWidth/imageWidth;
  // We should NOT do this here. A better question is portrait vs landscape.
  // todo: change per comment above
  const autoScaleFactor = isMobile() ? containerHeight/imageHeight : containerWidth/imageWidth;

  const [derivedImageWidth, derivedImageHeight] = [Math.round(autoScaleFactor*imageWidth*scaleFactor+displaceX), Math.round(autoScaleFactor*imageHeight*scaleFactor+displaceY)];

  const result = Math.round(derivedImageWidth)>=containerWidth && Math.round(derivedImageHeight)>=containerHeight;

  if (!result) {
    errorMessage = (Math.round(derivedImageWidth)<containerWidth) ? " must be WIDER" : "must be TALLER";
  }
  annotatedLog("makeImageSpotVisible", 
              `isImageGreedy=${result}: ${errorMessage}`, 
              `containerWidth=${containerWidth}; derivedImageWidth=${derivedImageWidth}; imageWidth=${imageWidth}; 
               containerHeight=${containerHeight}; derivedImageHeight=${derivedImageHeight}; imageHeight=${imageHeight}; 
               scaleFactor=${scaleFactor}; autoScaleFactor=${autoScaleFactor}; 
               displaceX=${displaceX}; displaceY:${displaceY}`);
  return result;
}

const makeImageSpotVisible = (containerWidth, containerHeight, imageWidth, imageHeight, scaleFactor, displaceX, displaceY, imageSpotX, imageSpotY, iter=0) => {
  const isVisible = isImageSpotVisible(containerWidth, containerHeight, imageWidth, imageHeight, scaleFactor, displaceX, displaceY, imageSpotX, imageSpotY);
  const isGreedy = isImageGreedy(containerWidth, containerHeight, imageWidth, imageHeight, scaleFactor, displaceX, displaceY);
  const autoScaleFactor = containerWidth/imageWidth;
  const [derivedImageWidth, derivedImageHeight] = [autoScaleFactor*imageWidth*scaleFactor+displaceX, autoScaleFactor*imageHeight*scaleFactor+displaceY];

  // annotatedLog("makeImageSpotVisible", ` ******** `, `scaleFactor:${scaleFactor};`);

  if (iter>15) {
    // annotatedLog("makeImageSpotVisible", `iter>15`, `left:${displaceX}; top:${displaceY}; scale:${scaleFactor}`);
    return {
      left: displaceX,
      top: displaceY,
      // top: (derivedImageHeight-autoScaleFactor*imageHeight)/2 - displaceY,
      // scale: scaleFactor
      scale: Math.max(1, scaleFactor)
    }
  }

  if (isVisible && isGreedy) {
    const result = {
      left: displaceX,
      // top: displaceY,
      top: (derivedImageHeight-autoScaleFactor*imageHeight + displaceY)/2,
      scale: Math.max(1, scaleFactor)
    }

    // annotatedLog("makeImageSpotVisible", `***************** isVisible && isGreedy`, `${JSON.stringify(result)}`);

    return result; 

  } else if (isVisible && !isGreedy) { // visible but now taking over full width and height of container -> scale
    // const autoScaleFactorX = (containerWidth/(imageWidth*scaleFactor) >= 1) ? 1.0*containerWidth/(imageWidth*scaleFactor) : scaleFactor;
    // const autoScaleFactorY = (containerHeight/(imageHeight*scaleFactor) >= 1) ? 1.0*containerHeight/(imageHeight*scaleFactor) : scaleFactor;
    const baseScale = containerWidth/imageWidth;
    // how much width will be used by the image if added a displacemente + normalReduction
    const autoScaleFactorX = containerWidth/(baseScale*scaleFactor*(imageWidth+displaceX)); //containerWidth/((imageWidth+displaceX)*scaleFactor);
    const autoScaleFactorY = containerHeight/(baseScale*scaleFactor*(imageHeight+displaceY)); //containerWidth/((imageWidth+displaceX)*scaleFactor);
    
    let scaleToUse=1;

    if (derivedImageWidth<containerWidth) {
    
      // image needs to be wider 

      // scaleToUse = 1.0/(derivedImageWidth/containerWidth);
      scaleToUse = scaleFactor*containerWidth/derivedImageWidth;
    } else {
      // image needs to be taller
      // scaleToUse = 1.0/(derivedImageHeight/containerHeight);
      scaleToUse = scaleFactor*containerHeight/derivedImageHeight;

    }
    
    const newScaleFactor = Math.min(1.0, scaleToUse);

    // const autoScaleFactorY = containerHeight/((imageHeight+displaceY)*scaleFactor);

    // const scaleToUse = Math.max(autoScaleFactorX, autoScaleFactorY);

    // annotatedLog("makeImageSpotVisible", `isVisible BUT !isGreedy`, `containerWidth=${containerWidth}; imageWidth=${imageWidth}; `);
    // annotatedLog("makeImageSpotVisible", `                       `, `autoScaleFactorX=${autoScaleFactorX}; autoScaleFactorY:${autoScaleFactorY}; `);
    // annotatedLog("makeImageSpotVisible", `                       `, `baseScale=${baseScale}; scaleToUse=${scaleToUse}; currentScaleFactor=${scaleFactor}; newScaleFactor:${newScaleFactor}; `);


    // const [derivedImageWidth, derivedImageHeight] = [scaleToUse*imageWidth+displaceX, scaleToUse*imageHeight+displaceY];
    // const [scaleFactorX, scaleFactorY] = [derivedImageWidth/containerWidth, derivedImageHeight/containerHeight];

    // annotatedLog("makeImageSpotVisible", `isVisible BUT !isGreedy`, `containerWidth=${containerWidth}; imageWidth=${imageWidth}; derivedImageWidth=${derivedImageWidth};`);
    // annotatedLog("makeImageSpotVisible", `                       `, `containerHeight=${containerHeight}; imageHeight=${imageHeight}; derivedImageHeight=${derivedImageHeight}; `);
    // annotatedLog("makeImageSpotVisible", `                       `, `autoScaleFactorX:${autoScaleFactorX}; autoScaleFactorY:${autoScaleFactorY}; scaleToUse=${scaleToUse}`);
    // annotatedLog("makeImageSpotVisible", `                       `, `scaleFactorX:${scaleFactorX}; scaleFactorY:${scaleFactorY}; newScaleFactor=${newScaleFactor}`);
    // annotatedLog("makeImageSpotVisible", `                       `, `currentScaleFactor=${scaleFactor}; newScaleFactor:${newScaleFactor}; `);

    return makeImageSpotVisible(containerWidth, containerHeight, imageWidth, imageHeight, scaleToUse, displaceX, displaceY, imageSpotX, imageSpotY, ++iter)
  } else { // not visible -> displace image horizontal and vertical
    const radius = 200;
    const scaledPosX = autoScaleFactor*imageSpotX+radius;
    const scaledPosY = autoScaleFactor*imageSpotY+radius;
    const [xDisplace, yDisplace] = [Math.min(0, containerWidth-scaledPosX), Math.min(0, (containerHeight-scaledPosY))];

    // annotatedLog("makeImageSpotVisible", `NOT isVisible`, `containerWidth=${containerWidth}; imageWidth=${imageWidth}; 
    //                                                        containerHeight=${containerHeight}; scaleFactor=${scaleFactor}; 
    //                                                        scaledPosX:${scaledPosX}; scaledPosY:${scaledPosY}; 
    //                                                        xDisplace:${xDisplace}; yDisplace:${yDisplace}`);

    return makeImageSpotVisible(containerWidth, containerHeight, imageWidth, imageHeight, scaleFactor, displaceX+xDisplace, displaceY+yDisplace, imageSpotX, imageSpotY, ++iter)

  }
}

const annotatedLog = (context, msg, annotation=null, timestamp=null) => {
  const d = new Date();
  // console.log(`>>>> ${context}, ${msg}, ${annotation} @ ${d.getTime()}`);
}

const getImagesLoadingStatus = (document) => {
  const images = Array.from(document.images);

  // We consider related content cards images as loaded, because they are at the very bottom of the page and not needed for the user to read the content
  const contentRelevantImages = new Set(images.filter(image => {
    const isFooterImage = image.closest(".footer") != null;
    return  !image.classList.contains("related-content-card-cover-image") && 
            !isFooterImage 
            //&&  // ignore related content cards images
            //!image.src.includes(".svg") // ignore svg images
  }));

  const uniqueUrls = new Set (Array.from(contentRelevantImages).map(image => image.src));  
  const urlsLoaded = new Set (Array.from(contentRelevantImages).filter((image) => {
    // console.log(`Checking image ${image.src} with complete=${image.complete} and naturalWidth=${image.naturalWidth}`);
    return image.complete}).map(image => image.src));
  const urlsLeft = new Set (Array.from(contentRelevantImages).filter(image => !image.complete).map(image => image.src));

  const imageLoadingStatus = {
    urlsLoaded: urlsLoaded,
    urlsLeft: urlsLeft,
    contentRelevantImages: uniqueUrls
  }

  // console.log(`PageLoaderInsertion getImagesLoadingStatus (imageLoadingStatus):`, imageLoadingStatus);

  return imageLoadingStatus;

}

function union(setA, setB) {
  const _union = new Set(setA);
  for (const elem of setB) {
    _union.add(elem);
  }
  return _union;
}

function intersection(setA, setB) {
  const _intersection = new Set();
  for (const elem of setB) {
    if (setA.has(elem)) {
      _intersection.add(elem);
    }
  }
  return _intersection;
}

function symmetricDifference(setA, setB) {
  const _difference = new Set(setA);
  for (const elem of setB) {
    if (_difference.has(elem)) {
      _difference.delete(elem);
    } else {
      _difference.add(elem);
    }
  }
  return _difference;
}

function difference(setA, setB) {
  const _difference = new Set(setA);
  for (const elem of setB) {
    _difference.delete(elem);
  }
  return _difference;
}

const areSetsEqual = (a, b) => a.size === b.size && [...a].every(value => b.has(value));

const  delay = (time) => {
  return new Promise(resolve => setTimeout(resolve, time));
}

const forceSameDomain = (url) => {
  // console.log(`**** in forceSameDomain for url ${url}`);
  if (url.toLowerCase().includes("https://")) {
        const urlComponents = url.split("/").slice(3);
        const normalisedUrl = `/${urlComponents.join("/")}`;
        // console.log(`**** new forceSameDomain url is ${normalisedUrl}`);
        return normalisedUrl;
  } else {
    return url;
  }

}

  export {triggerScrollTriggerRefresh, 
          changeInlineCss, 
          changeCssClasses, 
          shouldAnimate, 
          textContainerReduction, 
          toStandardPage, 
          toStandardPages, 
          shuffle, 
          rand, 
          swap, 
          isBrowser, 
          isMobile, 
          isTablet,
          isDesktop,
          deviceType,
          bodyPercentToAbsolutePositioning, 
          annotatedLog, 
          isImageSpotVisible, 
          isImageGreedy, 
          makeImageSpotVisible, 
          getImagesLoadingStatus, 
          union, 
          intersection, 
          symmetricDifference,
          difference, 
          areSetsEqual, 
          delay, 
          forceSameDomain};