import tableSVG from '@plone/volto/icons/table.svg';
import chronoSVG from '@plone/volto/icons/chrono.svg';
import appsSVG from '@plone/volto/icons/apps.svg';
import cameraSVG from '@plone/volto/icons/camera.svg';
import linkSVG from '@plone/volto/icons/link.svg';
import mailSVG from '@plone/volto/icons/email.svg';
import infoSVG from '@plone/volto/icons/info.svg';

import { ObjectBrowserWidgetMode } from '@plone/volto/components/manage/Widgets/ObjectBrowserWidget';

import ImageWith2TextsView from './ImageWith2Texts/ImageWith2TextsView';
import ImageWith2TextsEdit from './ImageWith2Texts/ImageWith2TextsEdit';

import ImageFadeImageView from './ImageFadeImage/ImageFadeImageView';
import ImageFadeImageEdit from './ImageFadeImage/ImageFadeImageEdit';

import ImageWithTextView from './ImageWithText/ImageWithTextView';
import ImageWithTextEdit from './ImageWithText/ImageWithTextEdit';

import FadingImageView from './FadingImage/FadingImageView';
import FadingImageEdit from './FadingImage/FadingImageEdit';

import HeroIRView from './HeroIR/HeroIRView';
import HeroIREdit from './HeroIR/HeroIREdit';

import HomeHeroView from './HomeHero/HomeHeroView';
import HomeHeroEdit from './HomeHero/HomeHeroEdit';

import CTAEdit from './CTA/CTAEdit';
import CTAView from './CTA/CTAView';

import RelatedContentCardsView from './RelatedContentCards/RelatedContentCardsView';
import RelatedContentCardsEdit from './RelatedContentCards/RelatedContentCardsEdit';

import ProductCardsView from './ProductCards/ProductCardsView';
import ProductCardsEdit from './ProductCards/ProductCardsEdit';

import CategoriesView from './Categories/CategoriesView';
import CategoriesEdit from './Categories/CategoriesEdit';

import ImageIRView from './ImageIR/ImageIRView';
import ImageIREdit from './ImageIR/ImageIREdit';

import HighlightBlockView from './HighlightBlock/HighlightBlockView';
import HighlightBlockEdit from './HighlightBlock/HighlightBlockEdit';

import VerticalDoubleImageHighlightView from './VerticalDoubleImageHighlight/VerticalDoubleImageHighlightView';
import VerticalDoubleImageHighlightEdit from './VerticalDoubleImageHighlight/VerticalDoubleImageHighlightEdit';

import ImageTextLinkView from './ImageTextLink/ImageTextLinkView';
import ImageTextLinkEdit from './ImageTextLink/ImageTextLinkEdit';

import DividerView from './Divider/DividerView';
import DividerEdit from './Divider/DividerEdit';

import ContactFormView from './ContactForm/ContactFormView';
import ContactFormEdit from './ContactForm/ContactFormEdit';

import ImageGalleryView from './ImageGallery/ImageGalleryView';
import ImageGalleryEdit from './ImageGallery/ImageGalleryEdit';

import ImageTickerView from './ImageTicker/ImageTickerView';
import ImageTickerEdit from './ImageTicker/ImageTickerEdit';

import FAQView from './FAQ/FAQView';
import FAQEdit from './FAQ/FAQEdit';


export {ImageWith2TextsView,ImageWith2TextsEdit,ImageFadeImageView,ImageFadeImageEdit,ImageWithTextView, 
        ImageWithTextEdit, FadingImageEdit, FadingImageView, HeroIRView, HeroIREdit, CTAEdit, CTAView, 
        RelatedContentCardsView, RelatedContentCardsEdit, ProductCardsView, ProductCardsEdit, CategoriesView, 
        CategoriesEdit, ImageIRView, ImageIREdit, HomeHeroView, HomeHeroEdit, HighlightBlockView, HighlightBlockEdit, 
        VerticalDoubleImageHighlightView, VerticalDoubleImageHighlightEdit, 
        ImageTextLinkView, ImageTextLinkEdit, ContactFormView, ContactFormEdit, 
        ImageTickerView, ImageTickerEdit, ImageGalleryView, ImageGalleryEdit, FAQView, FAQEdit, DividerView, DividerEdit};


export const customBlocks = {
  "imageWith2Texts" : {
    id: 'imageWith2Texts',
    title: 'ImageWith2Texts',
    icon: tableSVG,
    group: 'common',
    view: ImageWith2TextsView,
    edit: ImageWith2TextsEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    }
  },
  // Image With Text
  "imageFadeImage" : {
    id: 'imageFadeImage',
    title: 'Image Fade Image',
    icon: tableSVG,
    group: 'common',
    view: ImageFadeImageView,
    edit: ImageFadeImageEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    }
  },
  // Image With Text
  "imageWithText" : {
    id: 'imageWithText',
    title: 'Image With Text',
    icon: tableSVG,
    group: 'common',
    view: ImageWithTextView,
    edit: ImageWithTextEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    }
  },

  // Fading Image
  "fadingImage" : {
    id: 'fadingImage',
    title: 'Fading Image',
    icon: tableSVG,
    group: 'common',
    view: FadingImageView,
    edit: FadingImageEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    }
  },

  // Hero IR
  "heroir" : {
    id: 'heroir',
    title: 'Hero IR',
    icon: chronoSVG,
    group: 'common',
    view: HeroIRView,
    edit: HeroIREdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    }
  },

  // Home Hero
  "homeHero" : {
    id: 'homeHero',
    title: 'Home Hero',
    icon: chronoSVG,
    group: 'common',
    view: HomeHeroView,
    edit: HomeHeroEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    }
  },

  // Callout

  "callToAction" : {
    id: 'callToAction',
    title: 'CTA', 
    icon: chronoSVG,
    group: 'common',
    view: CTAView,
    edit: CTAEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    }
  },

  // Related Content Cards
  "relatedContentCards" : {
    id: 'relatedContentCards',
    title: 'Related Content Cards',
    icon: appsSVG,
    group: 'common',
    view: RelatedContentCardsView,
    edit: RelatedContentCardsEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    }
  },

  // Product Cards
  "productCards" : {
    id: 'productCards',
    title: 'Product Cards',
    icon: appsSVG,
    group: 'common',
    view: ProductCardsView,
    edit: ProductCardsEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    }
  } ,

  // Categories
  "categories" : {
    id: 'categories',
    title: 'Categories',
    icon: appsSVG,
    group: 'common',
    view: CategoriesView,
    edit: CategoriesEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    }
  } ,

  // Image IR
  "imageir" : {
    id: 'imageir',
    title: 'Image IR',
    icon: cameraSVG,
    group: 'common',
    view: ImageIRView,
    edit: ImageIREdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    }
  } ,

  // HighlightBlock
  "highlightblock" : {
    id: 'highlightblock',
    title: 'Highlight Block',
    icon: cameraSVG,
    group: 'common',
    view: HighlightBlockView,
    edit: HighlightBlockEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    }
  }, 
  
  // HighlightBlock
  "doubleimagehighlight" : {
    id: 'doubleimagehighlight',
    title: 'Double Image Highlight Block',
    icon: cameraSVG,
    group: 'common',
    view: VerticalDoubleImageHighlightView,
    edit: VerticalDoubleImageHighlightEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    }
  },

  "imagetextlink" : {
    id: 'imagetextlink',
    title: 'Image and Text Link',
    icon: linkSVG,
    group: 'common',
    view: ImageTextLinkView,
    edit: ImageTextLinkEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    }
  }, 
  
  "imagedivider" : {
    id: 'imagedivider',
    title: 'Image divider',
    icon: cameraSVG,
    group: 'common',
    view: DividerView,
    edit: DividerEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    }
  }, 

  "contactform" : {
    id: 'contactform',
    title: 'Contact Form',
    icon: mailSVG,
    group: 'common',
    view: ContactFormView,
    edit: ContactFormEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    }
  }, 

  "imagegallery" : {
    id: 'imagegallery',
    title: 'Image Gallery',
    icon: appsSVG,
    group: 'common',
    view: ImageGalleryView,
    edit: ImageGalleryEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    }
  }, 

  "imageticker" : {
    id: 'imageticker',
    title: 'Image Ticker',
    icon: appsSVG,
    group: 'common',
    view: ImageTickerView,
    edit: ImageTickerEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    }
  }, 

  "faq" : {
    id: 'faq',
    title: 'FAQ View',
    icon: infoSVG,
    group: 'common',
    view: FAQView,
    edit: FAQEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    }
  }, 

};

export default function install(config) {

  Object.entries(customBlocks).forEach(([key, value]) => {
    config.blocks.blocksConfig[key]=value;
  });

  if (!config.widgets.widget.pick_object)
    config.widgets.widget.pick_object = ObjectBrowserWidgetMode('link');

  return config;
};