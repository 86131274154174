import React, { useEffect, useRef, useState }  from 'react';
import {useDispatch, useSelector} from 'react-redux';
import './product-cards.less';
import {getPages} from '../../../../actions';


const ProductCardsView = (props) => {
  let ref = useRef();
  // const [stitch, setStitch] = useState("");

  var selector = props.data.selector;

  const dispatch = useDispatch();
  const loadedPages = useSelector(state => state.pages);

  useEffect(() => {
    dispatch(getPages(selector));

    }, []);


  useEffect(() => {
    }, [loadedPages]);


  const pages  = () => loadedPages.pages.map(page => {
    console.log(loadedPages);
    var parentElem = page.parent.id;
    var imgLink = page.preview_image == null ? "" : page.preview_image.download;
    return {
      title: page.title,
      description: page.description,
      image: imgLink.replace(parentElem, ""),
      link: page.id.replace(parentElem, "")
    }
  });



  const cards = () => pages().map((item) =>
    <div className="productCard" key="item-{Math.floor(Math.random() * 10000)}">
    <a href={item.link}>
      <div className="product-card-image">
        <img src={item.image}></img>
      </div>

      <div className="product-card-title">
        {item.title}
      </div>
      </a>
    </div>
  );

  const blockClass = "product-cards";

  return( <div className='productCardsBlock'>
          <div className={blockClass}>

          {cards()}
         </div>
         </div>)
};

export default ProductCardsView;

