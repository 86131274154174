/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

 import React from 'react';
 import { Container, List, Segment } from 'semantic-ui-react';
 import { useSelector } from 'react-redux';
 import { Link } from 'react-router-dom';
 import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
 import { Logo } from "@plone/volto/components";
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
 import { library } from "@fortawesome/fontawesome-svg-core";
 import { faLinkedinIn, faInstagram, faFacebookF} from '@fortawesome/free-brands-svg-icons';
 import { faPhone, faLocationDot, faEnvelope } from '@fortawesome/free-solid-svg-icons';
 // import './footer.less';
 
 const messages = defineMessages({
   copyright: {
     id: 'Copyright',
     defaultMessage: 'Copyright',
   },
 });

 library.add(faLinkedinIn, faInstagram, faFacebookF, faPhone, faLocationDot, faEnvelope);
 
 /**
  * Component to display the footer.
  * @function Footer
  * @param {Object} intl Intl object
  * @returns {string} Markup of the component
  */
 const Footer = ({ intl }) => {

    const currentLang = useSelector((state) => state.intl.locale);
    const contactLink = (currentLang === 'pt') ? '/pt/sobre-nos/contacto' : '/en/about-us/contact-us';
    const contactCta = (currentLang === 'pt') ? 'Envie-nos uma mensagem' : 'Send us a message' ;
    return (
      <Segment role="contentinfo" vertical padded inverted >
        <Container>
          <Segment basic inverted className="discreet footer ir-brown">
            <div className="footer-inner">
              <div className='footer-info'>
                <Logo color="white" />
                <div className='footer-address'>
                  Rua do Barreiro, 233 <br />4755-230 GILMONDE <br />PORTUGAL
                </div>
                <div className='footer-coordinates'>
                  <a href="https://goo.gl/maps/FYgtj9dtXeLYfFJdA" target="new"><FontAwesomeIcon icon="fa-solid fa-location-dot" />&nbsp; 41º 31' 0" N/8º 38' 54" W</a>
                </div>

                <div className='footer-phone-number'>
                  <a href="tel:+351253830070"><FontAwesomeIcon icon="fa-solid fa-phone" />&nbsp; +351 253 830 070</a>
                </div>
                
                <div className='contacts'>
                  <a href={contactLink}><FontAwesomeIcon icon="fa-solid fa-envelope" />&nbsp; {contactCta}</a>
                </div>
                <div className='social-media-links'>
                  <a href="https://www.linkedin.com/company/irm%C3%A3os-rodrigues/" target='_blank'><FontAwesomeIcon icon="fa-brands fa-linkedin-in" />&nbsp; LinkedIn</a>
                </div>
                
              </div>
              <div className='footer-links'>
                <div className='page-links'>
                  {/* <a href="" target='_blank'>About</a>
                  <a href="" target='_blank'>What</a>
                  <a href="" target='_blank'>Where</a> */}
                </div>
                <div className='social-media-links'>
                  {/* <a href="https://www.linkedin.com/company/irm%C3%A3os-rodrigues/" target='_blank'><FontAwesomeIcon icon="fa-brands fa-linkedin-in" /></a>               */}
                </div>

              </div>
              <div className='footer-logos'>
                {/* <img src="/footer-logos.svg" loading="lazy" alt="" /> */}
                <div className='footer-logo-row'>
                  {/* <img src="/oeko-tex-4876.svg" loading="lazy" alt="" /> */}
                  <img className="oeko-tex footer-image" src="/4876CIT_BLACK.svg" loading="lazy" alt="" />
                  <img className="oeko-tex footer-image" src="/9517CIT_BLACK.svg" loading="lazy" alt="" />
                </div>
                <div className='footer-logo-row'>
                  <img className="footer-image" src="/gots.svg" loading="lazy" alt="" />
                  <img className="footer-image enlarge" src="/OCS-100_White.png" loading="lazy" alt="" />
                  <img className="footer-image enlarge" src="/OCS-Blended_White.png" loading="lazy" alt="" />
                  <img className="footer-image enlarge" src="/RCS-100_White.png" loading="lazy" alt="" />
                  <img className="footer-image enlarge" src="/RCS-Blended_White.png" loading="lazy" alt="" />
                </div>
                <div className='footer-logo-row'>
                  <div className="footer-credits">Certified by Ecocert Greenlife, License nº 122966</div>
                </div>
                <div className='footer-logo-row'>
                  <img className="footer-image" id="sedex" src="/sedex-member.svg" loading="lazy" alt="" />  
                </div>  
                <div id="financed-by-logo-container" className='footer-logo-row'>
                  <a href="/pt/sobre-nos/ir_ficha_projecto_2023.pdf/@@download/file" target="new">
                    <img className="footer-image" id="finance" src="/financed-by-logos.png" loading="lazy" alt="" />
                  </a>
                </div>  

              </div>
            </div>
          </Segment>
        </Container>
      </Segment>
    );
  }  
 
 /**
  * Property types.
  * @property {Object} propTypes Property types.
  * @static
  */
 Footer.propTypes = {
   /**
    * i18n object
    */
 };
 
 export default injectIntl(Footer);