import React, { useEffect, useLayoutEffect, useRef, useState }  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux';
import { forceSameDomain } from '../utilities';

import './vertical-highlight.less';


const TEXT_SIZE = 65;
const MOBILE_TEXT_SIZE = 35;
const TEXT_LINE_HEIGHT = 75;
const MOBILE_TEXT_LINE_HEIGHT = 45;
const CTA_SIZE = 35;
const MOBILE_CTA_SIZE = 15;
const TEXT_CONTAINER_HEIGHT = 650;
const TEXT_CONTAINER_TOP = 750;
 
const [REWIND, UNLOADED, INIT, RUNNING, PAUSED, STOPPED, OBSERVING] = [-2,-1,0,1,2,3,4];

const VerticalDoubleImageHighlightView = (props) => {
  let ref = useRef();
  let imgRef = useRef();

  const pendingImages = useSelector((state) => { return state.pendingImages.pending });

  const [cutOffTop, setCutOffTop] = useState();
  const [textSize, setTextSize] = useState(TEXT_SIZE);
  const [textLineHeight, setTextLineHeight] = useState(TEXT_LINE_HEIGHT);
  const [ctaSize, setCtaSize] = useState(CTA_SIZE);
  const [textContainerHeight, setTextContainerHeight] = useState(TEXT_CONTAINER_HEIGHT);
  const [textContainerTop, setTextContainerTop] = useState(TEXT_CONTAINER_TOP);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [inlineTextStyle, setInlineTextStyle] = useState({});
  const [overallContainerStatus, setOverallContainerStatus] = useState(UNLOADED);

  let portrait_image = null;
  let landscape_image = null;

  const cta = (props.data.cta != undefined) ? props.data.cta : "Learn more";
  const link = (props.data.target_url != undefined && props.data.target_url[0]!=undefined) ? props.data.target_url[0].getURL : null;
  const visible = (props.data.hidden==true) ? false : true;
  const editEnabled = (props.data.editEnabled==true) ? true : false;
  const text = (props.data.text != undefined && props.data.text.length > 0) ? props.data.text : "Elegance isn't solely defined by what you wear. It's how you carry yourself, how you speak, what you read";
  const highlights = (props.data.bold_words != undefined && props.data.bold_words.length > 0) ? props.data.bold_words.split(",").map(w=>w.trim()) : ["yourself", "speak", "read"];
  const emphasisStyle = (props.data.colorize == true) ? 'colorize' : 'italicize';

  const triggerImageHeightCalculations = (caller) => {
    // console.log(`triggerImageHeightCalculations called from ${caller}; imageLoaded=${imageLoaded}; imgRef is completed: ${imgRef.current!=undefined && imgRef.current!=null && imgRef.current.complete}`);
    if (typeof window !== "undefined" && !imageLoaded && imgRef.current && imgRef.current.complete) {
      // console.log(`triggerImageHeightCalculations setting imageLoaded=true `);
      setImageLoaded(true);
    } 
  }

  useEffect(() => {
    if(imgRef.current && imgRef.current.complete && pendingImages==0) {
      triggerImageHeightCalculations("useEffect");
    } else {
      // console.log(`useEffect called but imgRef not ready. triggerImageHeightCalculations will be called by onLoad callback. imgRef.current is`);
      // console.log(imgRef.current);
    }
  }, []);

  useEffect(() => {
    if (pendingImages==0) {
     setOverallContainerStatus(INIT);
    }
  }, [pendingImages]);

  useEffect(() => {
    if (overallContainerStatus==INIT) {
      const body = document.getElementsByTagName("body")[0];
      const bodyWidth = body.offsetWidth;
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
    

      if (imageLoaded && visible && viewportWidth>=768) {

        // console.log(`>>>>>> Text Reduction Debug in DIHV. imageLoaded=${imageLoaded}, which means Vertical Image is`);
        // console.log(imgRef.current);
        // console.log(`       Vertical Image details: clientHeight=${imgRef.current.clientHeight}; naturalHeight=${imgRef.current.naturalHeight}`);


        const verticalImage = imgRef.current;
        const verticalImageHeight = (verticalImage.clientHeight!=0) ? verticalImage.clientHeight : (bodyWidth*verticalImage.naturalHeight/verticalImage.naturalWidth)/2;
        const _cutOffTop = `${verticalImageHeight/2}px`;

        const textDiv = ref.current.children[0].children[3];
        const textHeight = textDiv.clientHeight;
        const textBoxHeight = verticalImageHeight/2 - 2*50;

          // // const textHeight = text.clientHeight + 4*50; // Add some margin

          // const textReductionFactor = (textHeight>verticalImageHeight/2) ? (1.0*textHeight)/(verticalImageHeight/2) : 1;
          // const textPos = Math.max(0, viewportHeight-(text.clientHeight/textReductionFactor)-(4*54) );

          // console.log(`>>>>>> DIHV HEIGHT: bodyWidth=${bodyWidth}; verticalImage.clientHeight/2=${verticalImage.clientHeight/2}; verticalImageHeight/2=${verticalImageHeight/2}; verticalImage.naturalHeight=${verticalImage.naturalHeight}; verticalImage.naturalWidth=${verticalImage.naturalWidth}; _cutOffTop =  ${_cutOffTop}; textHeight=${textHeight}; reduction=${(verticalImageHeight/2.0)/textHeight}; verticalImage is`);
          
          if (textHeight > textBoxHeight) {
              const reduction=Math.min(1, textBoxHeight/textHeight); // don't scale up
              const [newTextSize, newLineHeight, newCtaSize, containerHeight, containerTop] = [TEXT_SIZE*reduction, TEXT_LINE_HEIGHT*reduction, CTA_SIZE*reduction, reduction*TEXT_CONTAINER_HEIGHT, reduction*TEXT_CONTAINER_TOP];

              // console.log(`>>>> Changing Text Size: newTextSize=${newTextSize}px newLineHeight=${newLineHeight} newCtaSize=${newCtaSize}`);
              setInlineTextStyle({
                fontSize: `${newTextSize}px`, 
                lineHeight: `${newLineHeight}px`,
              });
              
              // setTextSize(`${newTextSize}px`);
              // setTextLineHeight(`${newLineHeight}px`);
              setCtaSize(`${newCtaSize}px`);
              
          }
          // console.log(verticalImage);
          
          setTextContainerHeight(`${verticalImageHeight/2}px`);
          setTextContainerTop(`${verticalImageHeight/2}px`);
          setCutOffTop(_cutOffTop);
          setOverallContainerStatus(RUNNING);      
          // console.log(`>>>>>>>>>> Setting inline style to ${cutOffStyle}`);
      } else {
        setTextContainerHeight('auto');
        setTextContainerTop('0');
        setTextSize(MOBILE_TEXT_SIZE);
        setTextLineHeight(MOBILE_TEXT_LINE_HEIGHT);
        setCtaSize(MOBILE_CTA_SIZE);
        setOverallContainerStatus(STOPPED);
      }
    }
  }, [overallContainerStatus]);

  // useLayoutEffect(() => {
  //   if (pendingImages)
    
  // }, [imageLoaded]);

  if (props.data.portrait_image != undefined) {
    try {
        portrait_image = forceSameDomain(props.data.portrait_image[0]["getURL"] + "/@@images/image");
        
    } catch (e) {
        portrait_image = "";
    }  
  }

  if (props.data.landscape_image != undefined) {
    try {
        landscape_image = forceSameDomain(props.data.landscape_image[0]["getURL"] + "/@@images/image");
    } catch (e) {
        // console.log(">>>>>>>> ERROR ");
        // console.log(e);
        // console.log(props.data);
        // landscape_image = null;
    }  
  }

  const textContent = () => {
    // console.log(`called textContent. highlights, props`);
    // console.log(highlights);
    // console.log(props.data);
    const elements = text.split(" ").map((w) => {
      const stylableWord = w.replace(/[\.,\!\?\:]/, "");
      const segment =  (highlights.includes(stylableWord)) ? `<em class='${emphasisStyle}'>${w}</em>` : w;
      // console.log(`processing segmet for word *${w}*. stylableWord=${stylableWord}; segment = ${segment}; includes: ${highlights.includes(stylableWord)}`);
      // console.log(segment);
      return segment;
    }).join(" ");

    return elements;
  }


  return( 
  <div className="double-image-highlight-block" ref={ref}>
    {(visible || editEnabled) && 
        <div className='double-image-highlight-block-content-container'>
            <div className='portrait-image-container'>
                <img ref={imgRef} src={portrait_image} onLoad={triggerImageHeightCalculations("onLoad")}/>
            </div>
            <div className='landscape-image-container'>
            {landscape_image!=null &&
                <img src={landscape_image}/>
            }
            </div>

            <div className='background-cutoff' style={{height: cutOffTop, top: cutOffTop}}/>

            <div className='text-container' style={{height:textContainerHeight, top: textContainerTop}}>
                <h3 style={inlineTextStyle} dangerouslySetInnerHTML={{ __html: textContent() }}/>
                {link!=null && 
                <div className='cta'>
                    <a href={link} style={{fontSize: `${ctaSize}px`}}>{cta} <FontAwesomeIcon icon={faArrowRight} /></a>
                </div>
                

            }
            </div>
            
        </div>  
    } 
  </div>)
};

export default VerticalDoubleImageHighlightView;