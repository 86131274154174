/**
 * Gallery Images reducer.
 * @module reducers/content_types/pages
 */

 import { map } from 'lodash';
//  import { settings } from '~/config';

 import { GET_PAGES } from '../../constants/ActionTypes';

 const initialState = {
   error: null,
   loaded: false,
   loading: true,
   pages: []
 };

 /**
  * Faq reducer.
  * @function pages
  * @param {Object} state Current state.
  * @param {Object} action Action to be handled.
  * @returns {Object} New state.
  */
 export default function pages(state = initialState, action = {}) {
   switch (action.type) {
     case `${GET_PAGES}_PENDING`:
       return {
         ...state,
         error: "LOADING PAGES",
         loading: true,
         loaded: false,
         pages: [],
       };
     case `${GET_PAGES}_SUCCESS`:
       const results = action.result.items;
       return {
         ...state,
          error: "DATA RECEIVED SUCCESSFULLY",
          loaded: true,
          loading: false,
          pages: results
       };

     case `${GET_PAGES}_FAIL`:
       console.log(">>>>>>>>>>>>>>> GET_PAGES_FAIL");
       return {
         ...state,
         error: action.error,
         loading: false,
         loaded: false,
         pages: [],
       };
     default:
       return state;
   }
 }