import React, { useEffect, useRef, useState }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Gallery from './gallery-logic';
import bestGalleryOutOfSet from './gallery-generator';
import {grid, images} from './gallery-data';
import {getContentMetadata} from '../../../../actions';
import { forceSameDomain } from '../utilities';
import './image-gallery.less';




const [LOADING, UNLOADED, INIT, RUNNING, PAUSED, STOPPED, OBSERVING] = [-2,-1,0,1,2,3,4];

const ImageGalleryView = (props) => {
    let ref = useRef();
    const dispatch = useDispatch();

    const pendingImages = useSelector((state) => { return state.pendingImages.pending });
    const allLoaded = useSelector((state) => { return state.pendingImages.allLoaded });
    const imageMetadata = useSelector((state) => { return state.metadata });

    const [overallContainerStatus, setOverallContainerStatus] = useState(UNLOADED);
    const [gallery, setGallery] = useState(null);
    const [index, setIndex] = useState(-1);
    // const [galleryDataLoaded, setGalleryDataLoaded] = useState(false);

    // console.log(props.data.images);

    const standardiseUrl = (url) => { 
        let imgUrl =  forceSameDomain(url.replace(process.env.RAZZLE_API_PATH, "") + "/@@images/image"); 
        return imgUrl;
    }

    const imageUrls = (props.data.images != null && props.data.images != undefined) ? props.data.images.map((image) => standardiseUrl(image["getURL"])) : [];
    const imageUIDs = (props.data.images != null && props.data.images != undefined) ? props.data.images.map((image) => image["UID"]) : [];
    const imageData = Buffer.from(JSON.stringify(imageUrls)).toString("base64");
    const galleryTitle = (props.data.title!=null) ? props.data.title : "See more of this garment";
    const darkMode = (props.data.dark==true) ? "dark" : "light";
    const shouldShow = (props.data.hidden==true) ? false : true;

    const blockClassName = `image-gallery-block ${darkMode}`;
    const images = imageUrls.map((image) => {
        return {
            src: image,
            // width: "100%",
            // height: 650,
            isSelected: false,
            caption: image.Description,}
        });


    const galleryImages = (gallery!=null && gallery!=undefined) ? gallery.rows.map((row) => row.columns.map((column) => column.images)).flat(2) : [];
    const currentImage = (index>=0) ? galleryImages[index].image : null;
    const nextIndex = (index + 1) % galleryImages.length;
    const nextImage = images[nextIndex] || currentImage;
    const prevIndex = (index + images.length - 1) % galleryImages.length;
    const prevImage = images[prevIndex] || currentImage;

    const handleClick = (index, item) => setIndex(index);
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);

    const getClassName = (numItems) => {
        switch(numItems) {
            case 0:
                return "zero-items";
            case 1:
                return "one-item";
            case 2:
                return "two-items";
            case 3:
                return "three-items";
            case 4:
                return "four-items";
            case 5:
                return "five-items";
            case 6:
                return "six-items";
            case 7:
                return "seven-items";
            case 8:
                return "eight-items";
            case 9:
                return "nine-items";
            default:
                return "multi-item";
        }
    }

    const openLightBox = (image) => {
        const imgUrl = standardiseUrl(image.image.url);
        const imgId = image.id;
        const imgPos = galleryImages.map((img) => img.id).indexOf(imgId);
        setIndex(imgPos);
        // console.log(`opening lightbox for image ${image.id} @ ${imgPos}, with imgUrl=${imgUrl}`, image);
        // console.log(`images as follows: currentImage = ${imgUrl}, nextImage=${nextImage.src}, prevImage=${prevImage.src}, index=${imgPos}, nextIndex=${nextIndex}, prevIndex=${prevIndex}`);

    }

    const renderColumn = (row, column) => {
        // console.log(`rendering column ${column.id}`, column);
        const columnlass = `image-gallery-column ${getClassName(column.images.length)}`;
        const columnId = `gallery-row-column-${row.id}-${column.id}`;
        const images = column.images.map((image, imageIndex) => {
        // console.log(`rendering image ${image.id}`, image);
            return (
                <div className="image-gallery-image" id={`gallery-image-${image.id}`} key={image.image.id}>
                    <img src={image.image.url} onClick={() => openLightBox(image)} key={image.image.id}/>
                </div>
            );
        });

        // console.log(`images: ${images}`, images);
        return (
            <div key={columnId} className={columnlass} id={`gallery-column-${column.id}`} data-state={column.state}>
                {/* <h3>COLUMN {column.id} [${column.layout()}]</h3> */}
                {images}
            </div>
        );
    }

    const renderRow = (row) => {
        // console.log(`rendering row ${row.id}`, row);
        const columns = row.columns.map((column, index) => {
            return renderColumn(row, column);
            // return <div key={`c${index}`}></div>
        });

        const rowClass = `image-gallery-row ${getClassName(row.columns.length)}`;
        const rowId = `gallery-row-${row.id}`;
        
        return (
            <div className={rowClass} key={rowId} id={rowId}>
                {/* <h2>ROW {row.id}</h2> */}
                {/* {renderSomething(null)} */}
                {columns}
            </div>
        );
    }    
    

    const renderGallery = (activeGallery) => {
        const ACTIVE = "active";
        return ( shouldShow &&
            <div className="image-gallery-container" key="galery">
                {activeGallery.rows.filter((row) => row.state==ACTIVE).map((row) => (
                    renderRow(row)
                ))}
            </div>
        );
    };

    const generateImageGallery = (images) => {
        const galleryData = images.map((img) => {
            return {
                id: img.UID,
                name: img.title,
                description: img.description,
                url: (img.image != undefined && img.image.download != undefined) ? img.image.download.replace(process.env.RAZZLE_API_PATH, "") : "",
                width: (img.image != undefined && img.image.width != undefined) ? img.image.width : 0,
                height: (img.image != undefined && img.image.height != undefined) ? img.image.height : 0
            }
        });

        const grid = {
            rows: 2,
            columns: 4,
            currentRow: 0,
            currentColumn: 0,
            images: {
                files: galleryData,
            },
            layouts: {}
        }

        setGallery(bestGalleryOutOfSet(grid, 100));
    }

    // const renderGallery = (activeGallery) => {
    //     return (
    //         <div className="image-gallery-container">
    //             <h1>gallery goes here</h1>
    //         </div>
    //     );
    // };

    useEffect(() => {
        setOverallContainerStatus(UNLOADED);
        dispatch(getContentMetadata(imageUIDs));
    }, []);

    useEffect(() => {
        // console.log("ImageGallerytView: gallery changed");
        if (gallery!=null) {
            // console.log(`gallery:`, gallery);
            setOverallContainerStatus(RUNNING);
        }
    }, [gallery]);

    useEffect(() => {
        // console.log("ImageGallerytView: imageMetadata changed");
        if (imageMetadata!=null && imageMetadata.loaded && !imageMetadata.loading) {
            // console.log(`imageMetadata:`, imageMetadata);
            generateImageGallery(imageMetadata.metadata);
        }
    }, [imageMetadata]);


    // useEffect(() => {
    //     console.log(`ImageGalleryView: index changed to ${index}`);
    //     console.log(`>>> images as follows: currentImage, nextImage, prevImage, index=${index}, nextIndex=${nextIndex}, prevIndex=${prevIndex}`, currentImage, nextImage, prevImage);
    // }, [index]);
    // useEffect(() => {
    //     if (pendingImages==0 && allLoaded==true) {
    //         console.log("ImageGallerytView: pendingImages==0; LOAD NOW");
    //         setOverallContainerStatus(INIT);
    //     }
    // }, [pendingImages]);

    // useEffect(() => {   
    //     if (allLoaded==true && pendingImages==0) {
    //         console.log("ImageGallerytView: allLoaded==true; LOAD NOW");
    //         setOverallContainerStatus(INIT);
    //     }
    // }, [allLoaded]);


    useEffect(() => {
        // if (overallContainerStatus==INIT) {
        //     const encodedImageData = Buffer.from(imageData, 'base64').toString("utf-8");
        //     const imageUrls = JSON.parse(encodedImageData);
        //     setOverallContainerStatus(RUNNING);
        // }
    }, [overallContainerStatus]);

    if (overallContainerStatus!=RUNNING) {
        return( shouldShow &&
                <div className={blockClassName} ref={ref} data-images={imageData}>
                    <h2>IMAGE GALLERY GOES HERE</h2>
                </div>
            )
    } else  {
        return ( shouldShow &&
                <div className={blockClassName} ref={ref} data-images={imageData}>
                    <div className="image-gallery-title"><h2>{galleryTitle}</h2></div>
                        {renderGallery(gallery)}
                        {!!currentImage && (
                        <Lightbox
                            mainSrc={currentImage.url}
                            imageTitle={currentImage.title}
                            mainSrcThumbnail={currentImage.url}
                            nextSrc={nextImage.src}
                            nextSrcThumbnail={nextImage.src}
                            prevSrc={prevImage.src}
                            prevSrcThumbnail={prevImage.src}
                            onCloseRequest={handleClose}
                            onMovePrevRequest={handleMovePrev}
                            onMoveNextRequest={handleMoveNext} />
                        )}
                </div>
        )        
    }
};

export default ImageGalleryView;